import React from "react";
import WhyCloudLabAccessibilityCloud from "components/WhyCloudLabsTables/accessibility/accessibility-cloud";
import WhyCloudLabAccessibilityTraditional from "components/WhyCloudLabsTables/accessibility/accessibility-traditional";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  Layout,
  SEO,
  WhyCloudLabsInPageNav,
  WhyCloudLabsNav,
} from "components";
import { graphql } from "gatsby";

const WhyCloudLabAccessibilityPage = ({ data, location }) => {
  const title = "Everything You Need to Do Lab Work from Home";
  const description =
    "Any data run in the cloud stays organized and searchable for the life of your account";
  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <WhyCloudLabsInPageNav
          data={data.inPageNav.traditional}
          kind={"traditional"}
          tab={location && location.hash}
        >
          <WhyCloudLabAccessibilityTraditional
            data={data.inTable.traditional}
          />
        </WhyCloudLabsInPageNav>
        <WhyCloudLabsInPageNav
          data={data.inPageNav.cloud}
          kind={"cloud"}
          tab={location && location.hash}
        >
          <WhyCloudLabAccessibilityCloud data={data.inTable.cloud} />
        </WhyCloudLabsInPageNav>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabAccessibilityPage;

export const query = graphql`
  query WhyCloudLabAccessibilityPage {
    inPageNav: whyCloudLabsJson(section: { eq: "accessibility" }) {
      traditional {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
      cloud {
        tabRoutes
        title
        prevButton {
          text
          route
        }
        nextButton {
          text
          route
        }
      }
    }
    inTable: whyCloudLabsJson(section: { eq: "accessibility" }) {
      traditional {
        content {
          text
          list {
            text
          }
          title
          image
          alt
          reference {
            names
            journal
            location
          }
          footnote
        }
      }
      cloud {
        content {
          text
          list {
            text
          }
          title
          image
          alt
          footnote
        }
      }
    }
  }
`;
