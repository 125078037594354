import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList, CitationList } from "../components";
import { device } from "utils";

const WhyCloudLabAccessibilityCloud = ({ data }) => {
  const {
    content: [
      row1Content,
      row2Content,
      row3aContent,
      row3bContent,
      row4Content,
      row5aContent,
      row5bContent,
      row6Content,
      footerContent,
    ],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Row1TextNode>
          <S.Row1Text>{row1Content[0].text}</S.Row1Text>
          <S.Row1Text className="green">{row1Content[1].text}</S.Row1Text>
        </S.Row1TextNode>
      </S.Row1>
      <S.Row2>
        <S.Row2ImageNode>
          <S.ImageTitle>{row2Content[0].title}</S.ImageTitle>
          <S.Image
            src={row2Content[0].image}
            loading="lazy"
            alt={row2Content[0].alt}
            title={row2Content[0].title}
          />
        </S.Row2ImageNode>
        <S.Row2TextNode>
          <S.Row2Text>{row2Content[1].title}</S.Row2Text>
          <S.BulletsNode>
            {BulletList({
              data: row2Content,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2TextNode>
      </S.Row2>
      <S.Row3>
        <S.Row3TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row3aContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row3TextNode>
        <S.Row3TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row3bContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row3TextNode>
      </S.Row3>
      <S.Row4>
        <S.Image
          src={row4Content[0].image}
          loading="lazy"
          alt="remote-working-chart"
          title="Remote Working Chart"
        />
      </S.Row4>
      <S.Row5>
        <S.Row5TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row5aContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row5TextNode>
        <S.Row5TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row5bContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row5TextNode>
      </S.Row5>
      <S.Row6>
        <S.BulletsNode>
          {CitationList({
            data: row6Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row6>
      <S.FooterRow>
        <S.FooterImage
          src={footerContent[0].image}
          loading="lazy"
          alt="cloud-scientist"
          title="Cloud Scientist"
        />
      </S.FooterRow>
    </S.Table>
  );
};

WhyCloudLabAccessibilityCloud.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabAccessibilityCloud.defaultProps = {};

export default WhyCloudLabAccessibilityCloud;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableNodes = styled.div`
  padding: 3rem 5rem 0 5rem;
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2rem;
`;
S.Row1 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
`;
S.Row2 = styled.div`
  // Background defined in child nodes for responsive design
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 0 0 75rem;
  margin-top: 0.5rem;

  @media ${device.md} {
    flex: 0 0 45rem;
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 0 0 80rem;
  margin-top: 0.5rem;
  padding: 3rem 5rem;
  position: relative;

  @media ${device.md} {
    flex-direction: row;
    flex: 0 0 30rem;
    padding: 3rem 0 3rem 0;
  }
`;
S.Row4 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  margin-top: 0.5rem;
  padding: 4rem 3rem 0 6rem;
`;
S.Row5 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  padding: 4rem 0rem 3rem 0rem;
  display: flex;
`;
S.Row6 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  padding: 4rem 3rem 0 5rem;
  color: rgb(148, 158, 167);
`;
S.FooterRow = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  height: 30rem;
`;
S.Row1TextNode = styled(S.TableNodes)`
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #b9c2cb;
  padding: 2rem 6rem 2rem 3rem;
`;
S.Row2ImageNode = styled(S.TableNodes)`
  flex: 2;
  background: rgba(0, 0, 0, 0.6);
  padding: 4rem 3rem 0 5rem;
`;
S.Row2TextNode = styled(S.TableNodes)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  padding: 3.5rem 4.5rem;
  margin-top: 0.5rem;

  @media ${device.md} {
    margin-top: 0;
  }
`;
S.Row3TextNode = styled(S.TableNodes)`
  padding: 0;

  @media ${device.md} {
    padding: 0 5rem;
    flex: 1;
  }
`;
S.Row5TextNode = S.Row3TextNode;
S.Row1Text = styled.div`
  &.green {
    color: ${({ theme }) => theme.greenLink};
    padding-top: 1rem;
  }
`;
S.Row2Text = styled.div`
  font-weight: bold;
  padding-bottom: 1rem;
`;
S.BulletsNode = styled.div`
  > ul {
    margin-bottom: 0;
  }
`;
S.Image = styled.img`
  max-width: 100%;
  height: auto;
`;
S.ImageTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7rem;
  padding: 0 0 2rem 4.5rem;
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  right: 1rem;
  max-width: 100%;
`;
