import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BulletList, CitationList } from "../components";
import { device } from "utils";

const WhyCloudLabAccessibilityTraditional = ({ data }) => {
  const {
    content: [
      row1Content,
      row2aContent,
      row2bContent,
      row3Content,
      row4aContent,
      row4bContent,
      row5Content,
      footerContent,
    ],
  } = data;
  return (
    <S.Table>
      <S.Row1>
        <S.Row1ImageNode>
          <S.ImageTitle>{row1Content[0].title}</S.ImageTitle>
          <S.Image
            src={row1Content[0].image}
            loading="lazy"
            alt={row1Content[0].alt}
            title={row1Content[0].title}
          />
        </S.Row1ImageNode>
        <S.Row1TextNode>
          <S.ImageCaption>
            <em>{row1Content[0].text}</em>
          </S.ImageCaption>
          <S.ImageCaption className="reference">
            {row1Content[0].reference.names}
            <em>{` ${row1Content[0].reference.journal} `}</em>
            {row1Content[0].reference.location}
          </S.ImageCaption>
        </S.Row1TextNode>
      </S.Row1>
      <S.Row2>
        <S.Row2TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row2aContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2TextNode>
        <S.Row2TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row2bContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row2TextNode>
      </S.Row2>
      <S.Row3>
        <S.Image
          src={row3Content[0].image}
          loading="lazy"
          alt="remote-working-chart"
          title="Remote Working Chart"
        />
      </S.Row3>
      <S.Row4>
        <S.Row4TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row4aContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row4TextNode>
        <S.Row4TextNode>
          <S.BulletsNode>
            {BulletList({
              data: row4bContent,
              level: 0,
            })}
          </S.BulletsNode>
        </S.Row4TextNode>
      </S.Row4>
      <S.Row5>
        <S.BulletsNode>
          {CitationList({
            data: row5Content,
            level: 0,
          })}
        </S.BulletsNode>
      </S.Row5>
      <S.FooterRow>
        <S.FooterImage
          src={footerContent[0].image}
          loading="lazy"
          alt="traditional-scientist"
          title="Traditional Scientist"
        />
      </S.FooterRow>
    </S.Table>
  );
};

WhyCloudLabAccessibilityTraditional.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.array.isRequired,
  }),
};
WhyCloudLabAccessibilityTraditional.defaultProps = {};

export default WhyCloudLabAccessibilityTraditional;

const S = {};

S.Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -0.7rem;
  margin-right: -0.7rem;
`;
S.TableNodes = styled.div`
  padding: 3rem 5rem 0 5rem;
  color: #b1bac2;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2rem;
`;
S.Row1 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 0 0 52rem;

  @media ${device.md} {
    flex: 0 0 46rem;
    flex-direction: row;
  }
`;
S.Row2 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex: 0 0 90rem;
  margin-top: 0.5rem;
  padding: 3rem 0 3rem 0;
  position: relative;

  @media ${device.md} {
    flex: 0 0 30rem;
    flex-direction: row;
  }
`;
S.Row3 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  margin-top: 0.5rem;
  padding: 4rem 3rem 0 7rem;
`;
S.Row4 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  padding: 4rem 0rem 3rem 0rem;
  display: flex;
`;
S.Row5 = styled.div`
  background: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  padding: 4rem 3rem 0 5rem;
  color: rgb(148, 158, 167);
`;
S.FooterRow = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  height: 30rem;
`;
S.Row1ImageNode = styled(S.TableNodes)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.md} {
    flex: 2;
    padding: 0 1rem 0 3rem;
  }
`;
S.Row1TextNode = styled(S.TableNodes)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 8.5rem 3rem 8.5rem;

  @media (min-width: 480px) {
    padding: 2rem 12rem 3rem 12rem;
  }

  @media ${device.md} {
    flex: 1;
    justify-content: flex-end;
    padding: 0 5rem 8rem 0;
  }
`;
S.Row2TextNode = styled(S.TableNodes)`
  padding: 0 5rem;
  @media ${device.md} {
    flex: 1;
  }
`;
S.Row4TextNode = S.Row2TextNode;
S.BulletsNode = styled.div``;
S.Image = styled.img`
  max-width: 100%;
  transform: scale(0.9);

  @media ${device.md} {
    transform: scale(1);
  }
`;
S.ImageTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7rem;
`;
S.ImageCaption = styled.div`
  color: #949ea7;
  font-size: 1.4rem;
  line-height: 1.7rem;
  padding-bottom: 1rem;
  &.reference {
    word-break: break-all;
  }

  @media (min-width: 480px) {
    &.reference {
      word-break: normal;
    }
  }
`;
S.FooterImage = styled.img`
  position: absolute;
  bottom: -10rem;
  max-width: 100%;
`;
